<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>

            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan estratégico</BreadcrumbItem>
                <BreadcrumbItem :to="`/programa/${program.slug}`">{{ program.name }}</BreadcrumbItem>
                <BreadcrumbItem>Teoría del cambio</BreadcrumbItem>
            </Breadcrumb>

            <h2 class="program--title">
                {{ program.name }}
            </h2>
            <br>
            <h2 class="section--subtitle">
                Teoría del cambio: Plan estratégico {{ active_plan_obj.plan.init_year }} - {{ active_plan_obj.plan.end_year }}
                <Button v-if="CanEvaluate" style="margin-left: 15px; font-weight: normal !important;" type="primary" size="small" @click="onGoToEvaluation">Evaluar</Button>
            </h2>
            <br>
            <h3>
                Resultados a cinco años
                <Button v-if="CanAddResult" style="margin-left: 15px" type="primary" ghost size="small" @click="createResult('5years')">Agregar</Button>
            </h3>
            <EmptyState v-if=" Object.entries( active_plan_obj.results['5years'] ).length === 0 " icon="md-list-box" title="" :content="`La teoría del cambio de ${program.name} no tiene resultados creados.`"  />
            <template v-else>
                <template v-if="CanAddResult">
                    <ResultItem v-for="(result, i) in active_plan_obj.results['5years']"
                                :key="'thr_rsl_'+result.id"
                                :result="result"
                                :program="program"
                                :order="i+1"
                                :canModify="CanAddResult"
                    />
                </template>
                <template v-else>
                    <ResultItem v-for="(result, i) in active_plan_obj.results['5years']"
                                :key="'thr_rsl_'+result.id"
                                :result="result"
                                :program="program"
                                :order="i+1"
                                :canModify="CanAddResult"
                                evaluation
                    />
                </template>
            </template>
            <br>
            <h3>
                Resultados a tres años
                <Button v-if="CanAddResult" style="margin-left: 15px" type="primary" ghost size="small" @click="createResult('3years')">Agregar</Button>
            </h3>
            <EmptyState v-if=" Object.entries( active_plan_obj.results['3years'] ).length === 0 " icon="md-list-box" title="" :content="`La teoría del cambio de ${program.name} no tiene resultados creados.`"  />
            <template v-else>
                <template v-if="CanAddResult">
                    <ResultItem v-for="(result, i) in active_plan_obj.results['3years']"
                                :key="'thr_rsl_'+result.id"
                                :result="result"
                                :program="program"
                                :order="i+1"
                                :canModify="CanAddResult"
                    />
                </template>
                <template v-else>
                    <ResultItem v-for="(result, i) in active_plan_obj.results['3years']"
                                :key="'thr_rsl_'+result.id"
                                :result="result"
                                :program="program"
                                :order="i+1"
                                :canModify="CanAddResult"
                                evaluation
                    />
                </template>
            </template>

            <template v-if=" Object.entries( other_plans_obj ).length > 0 ">
                <br>
                <br>
                <hr>
                <br>
                <h3>Teorías del cambio anteriores</h3>
                <br>
                <div style="font-size: 0.9em">
                    <div v-for="(obj, i) in other_plans_obj" :key="`other_plan_s_${i}`">
                        <h2 class="section--subtitle">
                            Teoría del cambio: Plan estratégico {{ obj.plan.init_year }} - {{ obj.plan.end_year }}
                        </h2>
                        <h3>
                            Resultados a cinco años
                        </h3>
                        <template v-if=" Object.entries( obj.results['5years'] ).length === 0 ">
                            <p>La teoría del cambio de {{ program.name }} no tiene resultados creados.</p>
                        </template>
                        <ResultItem v-else v-for="(result, i) in obj.results['5years']"
                                    :key="'thr_rsl_'+result.id"
                                    :result="result"
                                    :program="program"
                                    :order="i+1"
                                    :canModify="false"
                                    evaluation
                        />
                        <br>
                        <h3>
                            Resultados a tres años
                        </h3>
                        <template v-if=" Object.entries( obj.results['3years'] ).length === 0 ">
                            <p>La teoría del cambio de {{ program.name }} no tiene resultados creados.</p>
                        </template>
                        <ResultItem v-else v-for="(result, i) in obj.results['3years']"
                                    :key="'thr_rsl_'+result.id"
                                    :result="result"
                                    :program="program"
                                    :order="i+1"
                                    :canModify="false"
                                    evaluation
                        />
                        <br>
                    </div>
                </div>
            </template>
        </template>

        <CreateResult v-if="CanAddResult"/>
        <UpdateResult v-if="CanAddResult"/>

        <CreateIndicator v-if="CanAddResult"/>
        <UpdateIndicator v-if="CanAddResult"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import ResultItem from "@/components/Program/components/Results/Item.vue"

    import CreateResult from "@/components/Program/components/Results/Create.vue"
    import UpdateResult from "@/components/Program/components/Results/Update.vue"
    import CreateIndicator from "@/components/Program/components/Results/Indicators/Create.vue";
    import UpdateIndicator from "@/components/Program/components/Results/Indicators/Update.vue";

    export default {
        data(){
            return {
                is_loading: true,
                program: null,
                active_plan_obj: null,
                other_plans_obj: [],
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                status: 'status_tdc',
                program_member: 'program',
            }),
            CanAddResult(){
                if( this.user.role_name === 'Super Admin' ) return true
                else if( this.user.role_name === 'Coordinación' ){
                    if( this.program_member != null ){
                        if( this.program_member.id === this.program.id ) return true;
                        else return false;
                    }
                    else return false;
                }
                else {
                    return false
                }
            },
            CanEvaluate(){
                return this.user.role_name === 'Super Admin'
            }
        },
        methods: {
            getProgram(slug){
                window.axios.post(this.$root.API_URL + '/programs/get-program', { field: 'slug', value: slug })
                    .then(response => {
                        if(response.data.success){
                            this.program = response.data.program
                            this.getTDCs()

                            /* Mark subitem as active */
                            this.$deleteSubitemsActives()
                            this.$markSubitemAsActive(this.program.name)
                        }
                    })
                    .catch(() => {
                        this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                    })
            },
            getTDCs(){
                window.axios.post(this.$root.API_URL + `/programs/get-tdcs/${this.program.id}`)
                    .then(response => {
                        if(response.data.success){
                            this.active_plan_obj = response.data.active_plan
                            this.other_plans_obj = response.data.other_plans
                            this.is_loading = false
                        }
                    })
                    .catch(() => {
                            this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                        })
            },
            createResult(type){
                window.EventBus.$emit('program-update--create-result', type)
            },
            onGoToEvaluation(){
                this.$router.push(`/programa/${this.program.slug}/tdc/evaluation`)
            }
        },
        mounted() {
            let program_slug = this.$route.params.name
            this.getProgram(program_slug)

            /* Collapse drawer menu */
            this.$collpaseDrawer('poa-content')

            window.EventBus.$on('program-details--add-result', (result) => {
                this.active_plan_obj.results[ result.type ].push( result )
            })

            window.EventBus.$on('program-details--update-result', (result) => {
                let type = result.type
                let results = this.active_plan_obj.results[ type ]

                let ri = window._.findKey( results, (r) => {
                    return r.id === result.id;
                })

                if( ri !== -1 ) this.$set( this.active_plan_obj.results[ type ], ri, result )
            })

            window.EventBus.$on('program-poa---delete-result', (result) => {
                let type = result.type
                let results = this.active_plan_obj.results[ type ]

                let ri = window._.findKey( results, (r) => {
                    return r.id === result.id;
                })

                if( ri !== -1 ) {
                    let rs = window._.omit( this.active_plan_obj.results[type], [ ri ] )
                    this.active_plan_obj.results[ type ] = rs
                    //delete this.active_plan_obj.results[type][ri]
                }

            })
        },
        components: {
            UpdateIndicator, CreateIndicator,
            ResultItem,
            UpdateResult,
            CreateResult,
        }
    }
</script>
