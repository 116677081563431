<template>
    <Modal class-name="scrollable vertical-center-modal" title="Nuevo plan estratégico" v-model="show" :footer-hide="true" :scrollable="true">
        <Form style="width: 100%" ref="planForm" :model="plan" :rules="ruleInline">
            <Row :gutter="16">
                <i-col span="12">
                    <FormItem label="Año inicio" prop="init_year">
                        <Input type="number" v-model="plan.init_year" placeholder="Año inicio"/>
                    </FormItem>
                </i-col>
                <i-col span="12">
                    <FormItem label="Año fin" prop="end_year">
                        <Input type="number" v-model="plan.end_year" placeholder="Año fin"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Visión" prop="vision">
                        <Input type="textarea" :autosize="{minRows: 5,maxRows: 8}" v-model="plan.vision" placeholder="Visión"/>
                    </FormItem>
                </i-col>
                <i-col span="24">
                    <FormItem label="Misión" prop="mission">
                        <Input type="textarea" :autosize="{minRows: 5,maxRows: 8}" v-model="plan.mission" placeholder="Misión"/>
                    </FormItem>
                </i-col>
                <i-col span="12">
                    <Button style="margin-top: 25px" size="large" long type="text" :loading="loading" @click="show=false" :disabled="loading">Cancelar</Button>
                </i-col>
                <i-col span="12">
                    <Button style="margin-top: 25px" size="large" long type="primary" :loading="loading" @click="save" :disabled="loading">Crear</Button>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                plan: {
                    init_year: "",
                    end_year: "",
                    vision: "",
                    mission: "",
                },
                loading: false,
                ruleInline: {
                    init_year: [
                        { required: true, message: 'Año inicio es requerido', trigger: 'change' },
                    ],
                    end_year: [
                        { required: true, message: 'Año fin es requerido', trigger: 'change' }
                    ],
                    vision: [
                        { required: true, message: 'Visión es requerida', trigger: 'change' }
                    ],
                    mission: [
                        { required: true, message: 'Misión es requerida', trigger: 'change' }
                    ],
                },
            }
        },
        methods: {
            save(){
                this.$refs.planForm.validate(valid => {

                    if(valid){
                        this.loading = true
                        window.axios.post(this.$root.API_URL + '/strategic-plan/create', { ...this.plan })
                        .then(response => {
                            const { success, plan } = response.data
                            if( success ){
                                window.EventBus.$emit('strategic-plan--create-plan', plan)
                                this.show = false
                                this.loading = false
                                this.clear()
                            }
                        })
                    }
                })
            },
            clear(){
                this.plan = {
                    init_year: "",
                    end_year: "",
                    vision: "",
                    mission: "",
                }
                this.$refs.planForm.resetFields()
            }
        },
        mounted(){
            window.EventBus.$on('strategic-plan--create', () => {
                this.show = true
            })
        }
    }
</script>
