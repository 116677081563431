import Vue from 'vue'
import VueRouter from 'vue-router'

import multiguard from 'vue-router-multiguard';
import { auth, guest } from "./guards"

import Plan from '../views/Plan'
import Area from '../views/Area'
import Users from '../views/Users'
import SignIn from '../views/SignIn'
import Program from '../views/Program'
import ProgramTDC from '../views/ProgramTDC'
import ProgramTDCEvaluation from '../views/ProgramTDCEvaluation'
import Project from '../views/Project'
import Evaluation from '../views/Evaluation'
import ProgramPOA from '../views/ProgramPOA'
import ProjectPOA from '../views/ProjectPOA'
import ProjectPOACalendar from '../views/ProjectPOACalendar'
import ProjectPOAEvMiddle from '../views/ProjectPOAEvMiddle'
import ProjectPOAEvFinal from '../views/ProjectPOAEvFinal'
import Evaluations from '../views/Evaluations'
import Competences from '../views/Competences'
import ImpDatesGrid from '../views/ImpDates'
import EvaluateUser from '../views/EvaluateUser'
import ForgotPassword from '../views/ForgotPassword'
import BudgetHeadings from '../views/BudgetHeadings'
import Autoevaluation from '../views/Autoevaluation'
import EvaluateProgram from '../views/EvaluateProgram'
import ImpactActivities from '../views/ImpactActivities'
import EvaluationScales from '../views/EvaluationScales'
import ProgramPOABitacora from '../views/ProgramPOABitacora'
import ProgramPOAEvFinal from '../views/ProgramPOAEvFinal'
import ProgramPOACalendar from '../views/ProgramPOACalendar'
import ProgramPOAEvMiddle from '../views/ProgramPOAEvMiddle'
import EvaluationsDetails from '../views/EvaluationsDetails'
import ForgotPasswordSecure from '../views/ForgotPasswordSecure'
import EvaluationsOfMyTeam from '../views/EvaluationsOfMyTeam'
import EvaluationsOfTheYear from '../views/EvaluationsOfTheYear'
import ProgramPOABudgetFill from '../views/ProgramPOABudgetFill'
import ProjectPOABudgetFill from '../views/ProjectPOABudgetFill'
import ProgramPOABudgetRecap from '../views/ProgramPOABudgetRecap'
import ProjectPOABudgetRecap from '../views/ProjectPOABudgetRecap'
import EvaluationsOfMyProgram from '../views/EvaluationsOfMyProgram'
import ProjectPOABudgetRequests from '../views/ProjectPOABudgetRequests'
import ProgramPOABitacoraRecap from '../views/ProgramPOABitacoraRecap'
import ProgramPOABudgetRequests from '../views/ProgramPOABudgetRequests'
import ProgramEvaluationsDetails from '../views/ProgramEvaluationsDetails'
import ProgramPOABudgetAvailable from '../views/ProgramPOABudgetAvailable'
import ProjectPOABudgetAvailable from '../views/ProjectPOABudgetAvailable'
import EvaluationsOfMyTeamDetails from '../views/EvaluationsOfMyTeamDetails'
import EvaluationsOfMyTeamDetailsSub from '../views/EvaluationsOfMyTeamDetailsSub'
import EvaluationsOfMyTeamDetailsDir from '../views/EvaluationsOfMyTeamDetailsDir'
import EvaluationsOfMyTeamDetailsCoord from '../views/EvaluationsOfMyTeamDetailsCoord'

import ProjectPOABitacora from '../views/ProjectPOABitacora'
import ProjectPOABitacoraRecap from '../views/ProjectPOABitacoraRecap'

import AreaPOA from "@/views/AreaPOA"
import AreaPOAEvFinal from "@/views/AreaPOAEvFinal"
import AreaPOAEvMiddle from "@/views/AreaPOAEvMiddle"
import AreaPOACalendar from "@/views/AreaPOACalendar"
import AreaPOABudgetFill from "@/views/AreaPOABudgetFill"
import AreaPOABudgetRecap from "@/views/AreaPOABudgetRecap"
import AreaPOABudgetRequests from "@/views/AreaPOABudgetRequests"
import AreaPOABudgetAvailable from "@/views/AreaPOABudgetAvailable"

import WorkEnv from '@/views/WorkEnv'
import WorkEnvSurvey from '@/views/WorkEnvSurvey'
import WorkEnvSurveyDetails from '@/views/WorkEnvSurveyDetails'
import WorkEnvSurveyDetailsCat from '@/views/WorkEnvSurveyDetailsCat'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: SignIn,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/forgot-password/:token',
        component: ForgotPasswordSecure,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/plan',
        component: Plan,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/usuarios',
        component: Users,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name',
        component: Program,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/tdc',
        component: ProgramTDC,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/tdc/evaluation',
        component: ProgramTDCEvaluation,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year',
        component: ProgramPOA,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year?tab=poa',
        redirect: '/programa/:name/:year/tab/poa'
    },
    {
        path: '/programa/:name/:year/tab/poa',
        component: ProgramPOA,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/schedule',
        component: ProgramPOACalendar,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/ev-middle',
        component: ProgramPOAEvMiddle,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/ev-final',
        component: ProgramPOAEvFinal,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/budget',
        component: ProgramPOABudgetAvailable,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/budget/available',
        component: ProgramPOABudgetAvailable,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/budget/fill',
        component: ProgramPOABudgetFill,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/budget/requests',
        component: ProgramPOABudgetRequests,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/budget/recap',
        component: ProgramPOABudgetRecap,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/bitacora',
        component: ProgramPOABitacora,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/bitacora/list',
        component: ProgramPOABitacora,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/bitacora/recap',
        component: ProgramPOABitacoraRecap,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name',
        component: Area,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/poa',
        component: AreaPOA,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/schedule',
        component: AreaPOACalendar,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/ev-middle',
        component: AreaPOAEvMiddle,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/ev-final',
        component: AreaPOAEvFinal,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/budget',
        component: AreaPOABudgetAvailable,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/budget/available',
        component: AreaPOABudgetAvailable,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/budget/fill',
        component: AreaPOABudgetFill,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/budget/requests',
        component: AreaPOABudgetRequests,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/area/:name/:year/tab/budget/recap',
        component: AreaPOABudgetRecap,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/rubros',
        component: BudgetHeadings,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluacion',
        component: Evaluation,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluacion/autoevaluacion',
        component: Autoevaluation,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluacion/:id',
        component: EvaluateUser,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluacion/area/:id',
        component: EvaluateProgram,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones',
        component: Evaluations,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/escalas-evaluaciones',
        component: EvaluationScales,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/mi-equipo',
        component: EvaluationsOfMyTeam,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/mi-equipo/:year/area',
        component: EvaluationsOfMyProgram,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/mi-equipo/:year/:id',
        component: EvaluationsOfMyTeamDetails,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/mi-equipo/:year/:id/coord',
        component: EvaluationsOfMyTeamDetailsCoord,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/mi-equipo/:year/:id/sub',
        component: EvaluationsOfMyTeamDetailsSub,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/mi-equipo/:year/:id/dir',
        component: EvaluationsOfMyTeamDetailsDir,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/:year',
        component: EvaluationsOfTheYear,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/:year/:id',
        component: EvaluationsDetails,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/evaluaciones/area/:year/:id',
        component: ProgramEvaluationsDetails,
        name: 'admin-program-evs',
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/competencias',
        component: Competences,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/imp-dates',
        component: ImpDatesGrid,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/impact-activities',
        component: ImpactActivities,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name',
        component: Project,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/poa',
        component: ProjectPOA,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/schedule',
        component: ProjectPOACalendar,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/ev-middle',
        component: ProjectPOAEvMiddle,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/ev-final',
        component: ProjectPOAEvFinal,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/programa/:name/:year/tab/budget',
        component: ProjectPOABudgetAvailable,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/budget/available',
        component: ProjectPOABudgetAvailable,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/budget/fill',
        component: ProjectPOABudgetFill,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/budget/requests',
        component: ProjectPOABudgetRequests,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/budget/recap',
        component: ProjectPOABudgetRecap,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/bitacora',
        component: ProjectPOABitacora,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/bitacora/list',
        component: ProjectPOABitacora,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/proyecto/:name/:year/tab/bitacora/recap',
        component: ProjectPOABitacoraRecap,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/work-env',
        component: WorkEnv,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/work-env-surveys',
        component: WorkEnvSurvey,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/work-env-surveys/:id',
        component: WorkEnvSurveyDetails,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/work-env-surveys/:id/tab/:tab',
        component: WorkEnvSurveyDetailsCat,
        beforeEnter: multiguard([auth]),
    },
    // {
    //     path: '*',
    //     component: Error404
    // },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => {
        return { x: 0, y: 0 }
    },
})

export default router
