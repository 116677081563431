<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>
            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan estratégico</BreadcrumbItem>
                <BreadcrumbItem>{{ program.name }}</BreadcrumbItem>
            </Breadcrumb>

            <Row :gutter="24">
                <i-col :xs="24" :sm="24" :md="12" :lg="12">
                    <h2 class="program--title">
                        {{ program.name }}
                        <span v-if="!program.enable"  style="margin-left: 15px; background-color: #f56c6c; font-size: 0.5em; color: white; border-radius: 2px;
                        padding: 5px;">
                            Desactivada
                        </span>
                    </h2>
                </i-col>
                <i-col :xs="24" :sm="24" :md="12" :lg="12" class-name="ivu-flex">
                    <div class="flex w-auto lg:justify-content-end gap-3 mt-3 mb-3 lg:mt-0">
                        <Button v-if="CanEvaluate" ghost type="primary" @click="onEditArea">Editar </Button>
                        <Button type="primary" @click="onGoToTDC">Teoría del cambio</Button>
                    </div>
                </i-col>

                <i-col :xs="24" :sm="24" :md="24" :lg="24">
                    <!-- Integrantes -->
                    <template v-if="members.length == 0">
                        <EmptyState icon="md-contacts" :title="`${program.name} no tiene integrantes`" cta_title="Agregar integrante" :cta="() => { addMember() }"/>
                    </template>
                    <template v-else>
                        <h3>
                            Integrantes
                            <Button v-if="CandAddProgramMembers" style="margin-left: 15px" type="primary" ghost size="small" @click="addMember">Agregar</Button>
                        </h3>
                        <div class="program-members--items">
                            <MemberItem v-for="member in SortedMembers" :key="'program_d_mbrs_'+member.id" :user="member.user" :member_id="member.id" :program="program" :show_actions="true" :type="member.type" />
                        </div>
                        <br>
                    </template>

                    <!-- Teoría del cambio -->
                    <!--<Button type="primary" class="accordion-btn" @click="collapseTDC">Teoría del cambio</Button>-->
                    <Collapse class="invisible hidden" v-model="collapse_tdc">
                        <Panel name="1">
                            <div slot="content">
                                <h3>Resultados a cinco años <Button v-if="CanAddResult" style="margin-left: 15px" type="primary" ghost size="small" @click="createResult('5years')">Agregar</Button></h3>
                                <!-- <div class="program-details-results-container"> -->
                                    <ResultItem v-for="(result, i) in FiveYearResults" :key="'thr_rsl_'+result.id" :result="result" :program="program" :order="i+1" :canModify="CanAddResult"/>
                                <!-- </div> -->

                                <br>

                                <h3>Resultados a tres años <Button v-if="CanAddResult" style="margin-left: 15px" type="primary" ghost size="small" @click="createResult('3years')">Agregar</Button></h3>
                                <div class="program-details-results-container">
                                    <ResultItem v-for="(result, i) in ThreeYearResults" :key="'thr_rsl_'+result.id" :result="result" :program="program" :order="i+1" :canModify="CanAddResult"/>
                                </div>

                                <br>
                                <Button type="primary" ghost class="accordion-btn" @click="collapseTDCEvaluation" >Evaluación de la teoría del cambio</Button>
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse class="invisible hidden" v-model="collapse_ev_tdc">
                        <Panel name="1">
                            <div slot="content">
                                <br>
                                <EvaluationScale :items="status"/>
                                <br><br>
                                <h3>Evaluación a cinco años</h3>
                                <ResultItem v-for="(result, i) in FiveYearResults" :key="'thr_rsl_'+result.id" :result="result" evaluation :program="program" :order="i+1" :canModify="CanAddResult"/>

                                <br><br>

                                <h3>Evaluación a tres años</h3>
                                <ResultItem v-for="(result, i) in ThreeYearResults" :key="'thr_rsl_'+result.id" :result="result" evaluation :program="program" :order="i+1" :canModify="CanAddResult"/>
                            </div>
                        </Panel>
                    </Collapse>

                </i-col>
            </Row>
            <br>
            <h3>POAs</h3>
            <br>
            <POAsList :program="program" :poas="poas"/>

            <AddMember :hasCoord="HasCoord"  v-if="CandAddProgramMembers"/>

            <EditProgram v-if="CanEvaluate" />
        </template>
    </div>
</template>

<script>
    import POAsList from './components/POAList'
    import AddMember from './components/Members/Create'
    import ResultItem from './components/Results/Item'
    import MemberItem from './components/Members/Item'
    import EditProgram from "@/components/Program/components/EditPopup"
    import { mapGetters } from 'vuex'
    import EvaluationScale from './../EvaluationScales/Legends'

    export default {
        data(){
            return {
                poas: [],
                program: null,
                members: [],
                results: [],
                is_loading: true,
                collapse_tdc: '0',
                collapse_ev_tdc: '0',
            }
        },
        watch: {
            '$route.params.name': function (name) {
                this.is_loading = true
                this.program = null
                this.results = []
                this.getProgram(name)
            },
            'collapse_tdc': function(v_new){
                if( v_new == '0' ){
                    if( this.collapse_ev_tdc == '1' ) this.collapse_ev_tdc = '0'
                }
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                status: 'status_tdc',
                program_member: 'program',
            }),
            SortedMembers(){
                return window._.sortBy(this.members, member => member.type)
            },
            ThreeYearResults(){
                return window._.orderBy(
                    window._.filter(this.results, (result) => {
                        return result.type == '3years'
                    })
                , ['order'], ['asc'])
            },
            FiveYearResults(){
                return window._.orderBy(
                    window._.filter(this.results, (result) => {
                        return result.type == '5years'
                    })
                , ['order'], ['asc'])
            },
            CandAddProgramMembers(){
                return this.$can('programs.members.add')
            },
            HasCoord(){
                let ci = window._.filter(this.members, member => member.type == 'coord')
                return ci.length >= 2
            },
            CanAddResult(){
                if( this.user.role_name == 'Super Admin' ) return true
                else if( this.user.role_name == 'Coordinación' ){
                    if( this.program_member != null ){
                        if( this.program_member.id == this.program.id ) return true;
                        else return false;
                    }
                    else return false;
                }
                else {
                    return false
                }
            },
            CanEvaluate(){
                return this.user.role_name == 'Super Admin'
            }
        },
        methods: {
            collapseTDCEvaluation(){
                // this.$collapse('tdc_accordion')
                this.collapse_ev_tdc = this.collapse_ev_tdc == '1' ? '0' : '1'
            },
            collapseTDC(){
                this.collapse_tdc = this.collapse_tdc == '1' ? '0' : '1'
            },
            getProgram(slug){
                window.axios.post(this.$root.API_URL + '/programs/get-program', { field: 'slug', value: slug })
                .then(response => {
                    if(response.data.success){
                        this.program = response.data.program
                        //this.results = response.data.results
                        this.poas = response.data.poas
                        this.members = response.data.members
                        this.is_loading = false

                        /* Mark subitem as active */
                        this.$deleteSubitemsActives()
                        this.$markSubitemAsActive(this.program.name)
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                })
            },
            createResult(type){
                window.EventBus.$emit('program-update--create-result', type)
            },
            update3YResult(){
                window.EventBus.$emit('program-update--result', 'results_3', this.program.results_3)
            },
            update5YResult(){
                window.EventBus.$emit('program-update--result', 'results_5', this.program.results_5)
            },
            addMember(){
                window.EventBus.$emit('program-details--add-member')
            },
            updateEvaluation(result){
                window.EventBus.$emit('program-tdc--update-evaluation', result, result.evaluations[0])
            },
            onEditArea(){
                window.EventBus.$emit('program--edit-program', this.program)
            },
            onGoToTDC(){
                this.$router.push(`/programa/${this.program.slug}/tdc`)
            }
        },
        mounted(){

            let program_slug = this.$route.params.name
            this.getProgram(program_slug)

            /* Collapse drawer menu */
            this.$collpaseDrawer('poa-content')

            window.EventBus.$on('program-details--add-poa-item', (poa) => {
                this.poas.push(poa)
            })

            window.EventBus.$on('program-details--remove-poa', (poa) => {
                let pi = window._.findIndex(this.poas, p => {
                    return p.id == poa.id
                })

                if( pi != -1 ){
                    this.poas.splice(pi, 1)
                    this.$Message.success('POA eliminado correctamente')
                }
            })

            window.EventBus.$on('program-details--add-member-to-program', (member) => {
                this.members.push(member)
                this.$updateCollapseHeight('tdc-members-div')
            })

            window.EventBus.$on('program-details--delete-member', (member_id) => {
                let mi = window._.findIndex(this.members, (m) => {
                    return m.id == member_id
                })

                if(mi != -1){
                    this.members.splice(mi, 1)
                    this.$updateCollapseHeight('tdc-members-div')
                }
            })

            window.EventBus.$on('program-tdc--update-result', (result) => {
                let ri = window._.findIndex(this.results, r => {
                    return r.id == result.id
                })

                if(ri != -1){
                    this.$set(this.results, ri, result)
                }
            })

            window.EventBus.$on('program-poa---delete-result', result => {
                let ri = window._.findIndex(this.results, r => {
                    return r.id == result.id
                })

                if( ri != -1 ){
                    this.results.splice(ri, 1)
                }
            })

            window.EventBus.$on('program--update-program', (program) => {
                this.program = { ...program }
            })

        },
        components: {
            POAsList,
            AddMember,
            MemberItem,
            ResultItem,
            EditProgram,
            EvaluationScale,
        }
    }
</script>
