<template>
    <div class="container top">
        <LoadingContainer v-if="is_loading"/>
        <template v-else>

            <Breadcrumb>
                <BreadcrumbItem to="/plan">Plan estratégico</BreadcrumbItem>
                <BreadcrumbItem :to="`/programa/${program.slug}`">{{ program.name }}</BreadcrumbItem>
                <BreadcrumbItem :to="`/programa/${program.slug}/tdc`">Teoría del cambio</BreadcrumbItem>
                <BreadcrumbItem>Evaluación</BreadcrumbItem>
            </Breadcrumb>

            <h2 class="program--title">
                {{ program.name }}
            </h2>
            <br>
            <h2 class="section--subtitle">
                <span style="display: block">Evaluación</span>
                Teoría del cambio: Plan estratégico {{ active_plan_obj.plan.init_year }} - {{ active_plan_obj.plan.end_year }}
            </h2>
            <EvaluationScale :items="status"/>

            <h3>
                Resultados a cinco años
            </h3>
            <EmptyState v-if=" Object.entries( active_plan_obj.results['5years'] ).length === 0 " icon="md-list-box" title="" :content="`La teoría del cambio de ${program.name} no tiene resultados creados.`"  />
            <ResultItem v-else v-for="(result, i) in active_plan_obj.results['5years']"
                        :key="'thr_rsl_'+result.id"
                        :result="result"
                        :program="program"
                        :order="i+1"
                        :canModify="CanAddResult"
                        evaluation
            />
            <br>
            <h3>
                Resultados a tres años
            </h3>
            <EmptyState v-if=" Object.entries( active_plan_obj.results['3years'] ).length === 0 " icon="md-list-box" title="" :content="`La teoría del cambio de ${program.name} no tiene resultados creados.`"  />
            <ResultItem v-for="(result, i) in active_plan_obj.results['3years']"
                        :key="'thr_rsl_'+result.id"
                        :result="result"
                        :program="program"
                        :order="i+1"
                        :canModify="CanAddResult"
                        evaluation
            />
        </template>

        <UpdateResultEvaluation/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import ResultItem from "@/components/Program/components/Results/Item.vue"

    import UpdateResultEvaluation from "@/components/Program/components/Results/EvaluateTDC.vue"
    import EvaluationScale from "@/components/EvaluationScales/Legends.vue"

    export default {
        data(){
            return {
                is_loading: true,
                program: null,
                active_plan_obj: null,
                other_plans_obj: [],
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                status: 'status_tdc',
                program_member: 'program',
            }),
            CanAddResult(){
                if( this.user.role_name === 'Super Admin' ) return true
                else if( this.user.role_name === 'Coordinación' ){
                    if( this.program_member != null ){
                        if( this.program_member.id === this.program.id ) return true;
                        else return false;
                    }
                    else return false;
                }
                else {
                    return false
                }
            },
        },
        methods: {
            getProgram(slug){
                window.axios.post(this.$root.API_URL + '/programs/get-program', { field: 'slug', value: slug })
                    .then(response => {
                        if(response.data.success){
                            this.program = response.data.program
                            this.getTDCs()

                            /* Mark subitem as active */
                            this.$deleteSubitemsActives()
                            this.$markSubitemAsActive(this.program.name)
                        }
                    })
                    .catch(() => {
                        this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                    })
            },
            getTDCs(){
                window.axios.post(this.$root.API_URL + `/programs/get-tdcs/${this.program.id}`)
                    .then(response => {
                        if(response.data.success){
                            this.active_plan_obj = response.data.active_plan
                            this.other_plans_obj = response.data.other_plans_obj
                            this.is_loading = false
                        }
                    })
                    .catch(() => {
                            this.$Message.error('Hubo un problema, no pudimos obtener información del programa');
                        })
            },
            createResult(type){
                window.EventBus.$emit('program-update--create-result', type)
            },
        },
        mounted() {

            if ( ! this.$itsSuperAdmin() ) this.$router.replace('/plan')
            else {
                let program_slug = this.$route.params.name
                this.getProgram(program_slug)
            }


            /* Collapse drawer menu */
            this.$collpaseDrawer('poa-content')

            window.EventBus.$on('program-tdc--update-result', (result) => {
                let type = result.type
                let results = this.active_plan_obj.results[ type ]

                let ri = window._.findKey( results, (r) => {
                    return r.id === result.id;
                })

                if( ri !== -1 ) this.$set( this.active_plan_obj.results[ type ], ri, result )
            })

        },
        components: {
            EvaluationScale,
            ResultItem,
            UpdateResultEvaluation,
        }
    }
</script>
