import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import * as Sentry from "@sentry/vue"
Sentry.init({
    Vue,
    dsn: "https://601c6ba269541d8766f1f56934432147@o4505167639281664.ingest.us.sentry.io/4507053225869312",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://evaluacion.ilsb.org.mx"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import './plugins/jquery'
import './plugins/EventBus'
import './plugins/iview/index'
import './plugins/scroll-threshold'
import './plugins/moment'
import './plugins/lodash'
import './plugins/element'
import './plugins/dexie'
import './plugins/axios'
import './plugins/vue2-filters'
import './plugins/vue-masonry'
import './plugins/diff'
import 'primeflex/primeflex.css'

import './directives'

// General components
import EmptyState from './components/General/EmptyState'
import LoadingComponent from './components/General/Loading'
import DiffText from './components/DiffText/Text'

Vue.component('LoadingContainer', LoadingComponent)
Vue.component('EmptyState', EmptyState)
Vue.component('DiffText', DiffText)

Vue.config.productionTip = false

new Vue({
    store,
    router,
    vuetify,
    data() {
        return {
            API_URL: process.env.VUE_APP_API_URL,
        }
    },
    mounted() {

        window.dexie.config.where({ name: 'auth' }).first(config => {

            if (config) {

                window.axios.post(this.API_URL+"/auth/user")
                .then(response => {
                    if(response.data){
                        this.$store.dispatch("SET_USER", response.data.user)
                        this.$store.dispatch("SET_PROGRAM", response.data.program)
                        this.$store.dispatch("SET_PERMISSIONS", response.data.permissions)
                        this.$store.dispatch("SET_WORK_ENV_SURVEY_ACTIVE", response.data.work_env_survey_active)

                        if (response.data.user.reset_password == true) {
                            setTimeout(() => {
                                window.EventBus.$emit('profile--show-reset-password-popup', true)
                            }, 1000)
                        }
                    }
                })
                .catch(() => {
                    window.dexie.config.get({ name: 'auth' }, e => {
                        if (e) window.dexie.config.delete(e.id)
                    })
                    this.$router.push("/")
                })

                window.axios.post(this.API_URL + `/indicators/get-statuses`)
                    .then(response => {
                    if(response.data.success){
                        this.$store.dispatch("SET_STATUS", response.data.statuses)
                    }
                })
                // .catch(() => {

                //     window.dexie.config.where({ name: 'auth' }).delete()
                //     this.$router.push("/")

                // })

            }

        })

    },
    render: h => h(App)
}).$mount('#app')
