<template>
    <Drawer class-name="plan-drawer" :closable="true" placement="right" width="600" :mask="true" v-model="drawer">
        <div class="container">
            <h1 style="margin-top: 50px; margin-bottom: 15px">Planes anteriores</h1>

            <div v-for=" (plan, i) in oldPlans " :key="`old_plan_${i}`" style="margin-bottom: 40px">
                <h2 class="section--subtitle">
                    Plan estratégico {{ plan.init_year }} - {{ plan.end_year }}
                </h2>
                <div style="padding-left: 10px">
                    <h3 style="margin-top: 10px;">Visión</h3>
                    <p>{{ plan.vision }}</p>
                    <h3 style="margin-top: 10px;">Misión</h3>
                    <p>{{ plan.mission }}</p>
                    <h3 style="margin-top: 10px;">Resultados institucionales</h3>
                    <div v-for="(r, i) in plan.rs" :key="'rs_p_'+i" class="plan-rs--item small" :class="{ 'even' : i % 2 }">
                        <h3 class="plan-rs--item--title small">{{  Number.parseInt(i) + 1 }}</h3>
                        <div class="plan-rs--item--background small">
                            <p>{{ r }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Drawer>
</template>

<script>
    import plan from "../../../views/Plan.vue";

    export default {
        name: 'OldPlansSidebar',
        computed: {
            plan() {
                return plan
            }
        },
        props: {
            oldPlans: { required: true, type: [ Array, Object ] },
        },
        data(){
            return {
                drawer: false,
            }
        },
        mounted() {
            window.EventBus.$on('strategic-plan--open-old-drawer', () => {
                this.drawer = true
            })
        }
    }
</script>
