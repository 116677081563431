<template>
    <div>
        <CreateModal v-if="CanEditPlanInfo"/>
        <UpdateModal v-if="CanEditPlanInfo"/>
        <UpdateRIModal/>
        <CreateRIModal v-if="CanAddResultPlan"/>

        <LoadingContainer v-if="is_loading"/>
        <div v-else id="print" class="container top">
            <Row type="flex" align="middle">
                <i-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <h1 class="section--subtitle">Plan estratégico {{ ActivePlan.init_year }} - {{ ActivePlan.end_year }}</h1>
                </i-col>
                <i-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="display: flex; flex-direction: column; display: flex; justify-content: flex-end; gap: 10px;">
                    <div style="width: 100%; display: flex; justify-content: flex-end">
                        <Button v-if="CanEditPlanInfo" style="margin-right: 15px" class="display-none-print" ghost type="primary" @click="onCreatePlan">Nuevo plan</Button>
                        <Button v-if="OldPlans.length > 0" class="display-none-print" ghost type="primary" @click="onOpenOldDrawer">Planes anteriores</Button>
                    </div>
                    <div style="width: 100%; display: flex; justify-content: flex-end">
                        <Button v-if="CanEditPlanInfo" style="margin-right: 15px" class="display-none-print" ghost type="primary" @click="updatePlan">Editar</Button>
                        <Button class="display-none-print" type="primary" @click="download">Descargar</Button>
                    </div>
                </i-col>
            </Row>
            <br>

            <h2>Visión</h2>
            <p>{{ ActivePlan.vision }}</p>
            <br>

            <h2>Misión</h2>
            <p>{{ ActivePlan.mission }}</p>
            <br><br><br>

            <Row>
                <i-col :xs="24" :sm="24" :lg="12">
                    <h2>Resultados Institucionales</h2>
                </i-col>
                <i-col :xs="24" :sm="24" :lg="12" v-if="CanAddResultPlan">
                    <Button v-if="CanAddResultPlan" style="float: right" class="display-none-print" type="primary" @click="addRI">Agregar</Button>
                </i-col>
            </Row>
            <br>

            <div class="plan-rs">

                <EmptyState v-if="ActivePlan.rs.length === 0" icon="ios-archive" title="Sin resultados" :content="`El plan estratégico ${ActivePlan.init_year} - ${ActivePlan.end_year} no tiene resultados por ahora.`" />

                <div v-for="(r, i) in ActivePlan.rs" :key="'rs_p_'+i" class="plan-rs--item" :class="{ 'even' : i % 2 }">
                    <h2 class="plan-rs--item--title">{{  Number.parseInt(i) + 1 }}</h2>
                    <div class="plan-rs--item--background">
                        <Dropdown v-if="CanUpdateResultPlan || CanRemoveResultPlan" trigger="click" class="plan-rs--item--actions">
                            <a href="javascript:void(0)">
                                <Icon color="grey" size="16" type="md-more"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem v-if="CanUpdateResultPlan" @click.native="updateRI(i, r)">Editar</DropdownItem>
                                <DropdownItem v-if="CanRemoveResultPlan" @click.native="deleteRI(i)">Eliminar</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <p>{{ r }}</p>
                    </div>
                </div>
            </div>

        </div>

        <OldPlansSidebar :old-plans="OldPlans" />
    </div>
</template>

<script>
    import download from './../../mixins/download'
    import UpdateModal from './components/Update'
    import CreateModal from './components/Create'
    import UpdateRIModal from './components/UpdateRI'
    import CreateRIModal from './components/CreateRI'
    import OldPlansSidebar from "@/components/Plan/components/OldPlansSidebar.vue";

    export default {
        mixins: [download],
        data(){
            return {
                is_loading: true,
                plans: []
            }
        },
        computed: {
            ActivePlan(){
                return this.plans.filter(p => p.active === true)[0]
            },
            OldPlans(){
                return this.plans.filter(p => p.active === false)
            },
            CanEditPlanInfo(){
                return this.$can('plan.edit')
            },
            CanAddResultPlan(){
                return this.$can('plan.results.add')
            },
            CanUpdateResultPlan(){
                return this.$can('plan.results.edit')
            },
            CanRemoveResultPlan(){
                return this.$can('plan.results.remove')
            },
        },
        methods: {
            download(){
                this.downloadAsImage(document.getElementById('print'), 'plan-inst.png')
            },
            getPlans(){
                window.axios.post(this.$root.API_URL + '/strategic-plan/get')
                .then(response => {
                    const { success, plans  } = response.data
                    if( success ){
                        this.plans = plans
                        this.is_loading = false
                    }
                })
                .catch(() => {
                    this.$Message.error('Hubo un problema, no pudimos completar esta acción');
                })
            },
            onCreatePlan(){
                window.EventBus.$emit('strategic-plan--create')
            },
            updatePlan(){
                window.EventBus.$emit('strategic-plan--update', this.ActivePlan)
            },
            addRI(){
                window.EventBus.$emit('strategic-plan--create-ri')
            },
            updateRI(index, text){
                window.EventBus.$emit('strategic-plan--update-ri', index, text)
            },
            onUpdatePlan(plan){
                let pi = window._.findIndex(this.plans, p => p.id === plan.id)
                if( pi !== -1 ) this.$set(this.plans, pi, plan)
                //if( pi !== -1 ) this.plans[pi] = plan
            },
            deleteRI(index){
                this.$Modal.confirm({
                    title: 'Eliminar RI',
                    content: `<p>¿Deseas eliminar el RI${index+1}?</p>`,
                    okText: 'Eliminar',
                    cancelText: 'Cancelar',
                    onOk: () => {
                        window.axios.post(this.$root.API_URL + '/strategic-plan/delete-ri', { id: this.ActivePlan.id, i: index })
                        .then(response => {
                            const { success, plan  } = response.data
                            if(success){
                                this.onUpdatePlan( plan )
                                this.$Message.success('RI eliminado correctamente');
                            }
                        })
                    },
                });
            },
            onOpenOldDrawer(){
                window.EventBus.$emit('strategic-plan--open-old-drawer')
            }
        },
        mounted(){
            /* Unmark active subitem  */
            this.$deleteSubitemsActives()

            // Get plans
            this.getPlans()

            window.EventBus.$on('strategic-plan--create-plan', (plan) => {
                this.plans.forEach(p => p.active = false)
                this.plans.push(plan)
            })

            window.EventBus.$on('strategic-plan--update-plan', (plan) => {
                this.onUpdatePlan(plan)
            })
        },
        components: {
            OldPlansSidebar,
            CreateModal,
            UpdateModal,
            UpdateRIModal,
            CreateRIModal,
        }
    }
</script>
